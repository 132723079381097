<header class="w-full h-16 pl-6 pr-3 z-40 flex items-center justify-between shadow-lg bg-topbar">
    <div class="block">
        <button class="flex p-2 items-center rounded-full bg-white shadow text-gray-500 text-md"
            (click)="onNavButtonClick.emit()">
            <svg width="20" height="20" class="text-gray-400" fill="currentColor" viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                </path>
            </svg>
        </button>
    </div>
    <div class="relative z-20 flex items-center justify-between h-full md:w-full">
        <!-- <app-breadcrumbs class="flex-grow flex-shrink-0"></app-breadcrumbs> -->
        <div data-cy="title"
            class="text-3xl text-black dark:text-white font-semibold w-full flex-grow text-center whitespace-nowrap">
            {{pageTitle}}</div>
        <div class="relative p-1 flex items-center flex-shrink-0 space-x-4 justify-end">
            <button class="flex p-2 items-center rounded-full text-gray-400 hover:text-gray-700 bg-white shadow text-md"
                (click)="logout()">
                <i class="w-5 h-5 fa fa-solid fa-arrow-right-from-bracket text-red-500"></i>
            </button>

            <button class="flex p-2 items-center rounded-full bg-white shadow text-md "
                [class.text-red-400]="balance.balance <=0" [class.text-green-700]="balance.balance>10"
                [class.text-orange-400]="0<balance.balance && balance.balance<10"
                [title]="balance.accountBalance.toFixed(2) +' crediti\n' + balance.balance.toFixed(2) + ' disponibili'">
                <i class="w-5 h-5 fa fa-solid fa-coins"></i>
            </button>
            <button [routerLink]="['/','user','messages']"
                class="flex p-2 items-center rounded-full bg-white shadow text-gray-400 hover:text-gray-700 text-md">
                <i class="w-5 h-5 fa fa-solid fa-bell"></i>
            </button>
            <span class="w-1 h-8 rounded-lg bg-gray-200">
            </span>
            <button class="flex items-center text-gray-500 dark:text-white text-md"
                (click)="showProfilePanel = !showProfilePanel">
                <img *ngIf="profileJson?.picture" [alt]="user?.firstName+ ' '+ user?.lastName"
                    [src]="profileJson?.picture" class="mx-auto object-cover rounded-full h-10 w-10 " />
                <svg width="20" height="20" class="ml-2 text-gray-400 transition-transform duration-300"
                    [ngClass]="[showProfilePanel ? 'rotate-90': '' ]" fill="currentColor" viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z">
                    </path>
                </svg>

            </button>
            <div class="absolute p-4 top-16 right-0 w-[36rem] rounded bg-white flex flex-col items-center justify-start shadow-xl"
                *ngIf="showProfilePanel">
                <div class="flex justify-between w-full items-center">
                    <div>
                        <div class="text-lg">{{user?.firstName}} {{user?.lastName}}</div>
                        <div class="text-sm">{{user?.email}}</div>
                        <div class="text-primary">{{user?.company?.name}}</div>
                    </div>
                    <div class="h-20 w-20">
                        <img *ngIf="profileJson?.picture" [src]="profileJson?.picture"
                            class="mx-auto object-cover rounded-full h-full w-full " />
                    </div>
                </div>
                <div class="flex w-full justify-between">
                    <div class="mt-2 font-semibold">Credito residuo: {{balance.balance | number}} €
                    </div>
                    <div class="mt-2 font-semibold">Ultimo accesso: {{profileJson?.updated_at | date : 'dd.MM.yyyy'}}
                    </div>
                </div>
                <table class="w-full my-4 rounded shadow-md" *ngIf="activations$| async as activations">
                    <thead class="text-primary">
                        <tr>
                            <th class="p-1">Abbonamento</th>
                            <th class="p-1">Data attivazione</th>
                            <th class="p-1">Data Scadenza</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of activations">
                            <td class="p-1 text-center">{{item.bundle?.name}}</td>
                            <td class="p-1 text-center">{{item.startDate | date: 'dd.MM.YYYY'}}</td>
                            <td class="p-1 text-center">{{item.endDate | date: 'dd.MM.YYYY'}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex mt-4 w-full justify-between text-sm gap-1">
                    <a (click)="showProfilePanel=false"
                        class="rounded bg-secondary text-white hover:ring-1 ring-secondary px-2 py-1"
                        [routerLink]="['/','user','balance']">Estratto
                        conto</a>
                    <a (click)="showProfilePanel=false"
                        class="rounded bg-secondary text-white marker:hover:ring-1 ring-secondary px-2 py-1"
                        [routerLink]="['/','user','requests']">Servizi
                        richiesti</a>
                    <a (click)="showProfilePanel=false"
                        class="rounded bg-secondary text-white hover:ring-1 ring-secondary  px-2 py-1"
                        [routerLink]="['/','user','usage']">Contatore
                        richieste</a>
                    <button (click)="pwdReset()" [disabled]="disablePwdButton"
                        class="rounded bg-primary text-white hover:ring-1 ring-primary disabled:bg-slate-500 transition-colors duration-1000 px-2 py-1">Cambio
                        Password</button>
                </div>
            </div>
        </div>
    </div>
</header>