import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Data, ParamMap, Router } from '@angular/router';
import { Observable, of, delay, switchMap, catchError, map, tap } from 'rxjs';
import { RequestModel } from 'src/app/models/request.model';
import { ServiceNote, ServiceParamFieldModel } from 'src/app/models/service.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { QueriesService } from '../../services/queries.service';
import { RequestService } from '../../services/request.service';
import { SubscriptionService } from '../../services/subscription.service';
import { FieldPatterns } from 'src/app/models/service.model';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-requestform',
  templateUrl: './requestform.component.html',
  styles: [
  ]
})
export class RequestformComponent implements OnInit {

  constructor(
    private service: SubscriptionService,
    private requestService: RequestService,
    private queriesService: QueriesService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService
  ) { }
  subscription$: Observable<SubscriptionModel> | undefined
  formData: any = {};
  id: number | undefined
  options: any = {}
  showReportPreview?: RequestModel
  showSubmissionConfirm: boolean = false
  priceCalculationInfo: any
  confirmActionData: ActionComponentData = {
    subscriptionId: 0,
    title: '',
    description: '',
    price: 0,
    type: 'request'
  }
  fields: ServiceParamFieldModel[] = []
  notes: ServiceNote[] | undefined = []
  fp = FieldPatterns
  ngOnInit(): void {
    this.subscription$ = this.route.data.pipe(
      switchMap((data: Data) => {
        if (data['serviceSlug']) {
          return this.service.getByServiceSlug(data['serviceSlug'])
        } else {
          return this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
              this.service.getByServiceSlug(params.get('id')!)
            )
          )
        }
      })
    )
    this.subscription$.subscribe(s => {

      this.fields = s?.service?.fields || []

      this.id = s.id || 0
      this.confirmActionData = {
        subscriptionId: s.id || 0,
        title: s.service?.name || '',
        description: s.service?.description || '',
        price: s.price || 0,
        type: 'request'
      }
      this.priceCalculationInfo = s.service?.priceCalculation
      this.notes = s.service?.notes

    })

  }

  onFormSubmit(data: any) {
    this.formData = data
    if (this.confirmActionData.price == 0) {
      this.makeRequest()
    } else {
      this.prepareActionPanel()
    }
  }


  makeRequest() {
    let notes: any = {}
    Object.keys(this.formData).forEach(k => {
      let found = this.fields.find(f => f.name == k)
      found?.description && (notes[found.description] = this.formData[k])
    })

    this.sharedService.submitRequestWithParamsAndNotes({
      params: this.formData,
      notes,
      confirmActionData: { subscriptionId: this.id || 0 }
    }).pipe(
      map((res: any) => {
        this.sharedService.loadingMsg$ = ""
        this.sharedService.hideActionPopup()
        if (res?.status == 'queued') {
          if (res.service?.showReportPreview) {
            this.showReportPreview = res
          } else {
            this.showReportPreview = undefined
            this.showSubmissionConfirm = true
          }
        }
      }),
    ).subscribe()
  }
  getOptions(param: any) {
    this.sharedService.loadingMsg$ = "Caricamento.."
    this.queriesService.post({
      channel: param.inputChannel,
      data: Object.fromEntries(param.dependencies?.map((d: string) => [d, this.formData[d]]))
    }).subscribe(d => {
      this.options[param.name] = d.items
      // console.log("QUERY RESULT", this.options[param.name])
      this.sharedService.loadingMsg$ = ""
    })
  }
  updateDependentFields(field: ServiceParamFieldModel, fields: ServiceParamFieldModel[]) {
    fields.filter(p => p.dependencies?.every(d => d = field.name)).map(p => {
      // console.log("AUTOLOADING OPTIONS FOR ", p.name)
      this.getOptions(p)
    })

  }

  prepareActionPanel() {
    // console.log("PARAMS", {
    //   channel: this.priceCalculationInfo?.channel,
    //   data: this.formControlGroup.value
    // })
    if (this.priceCalculationInfo?.channel) {
      this.sharedService.loadingMsg$ = 'Caricamento costi...'
      // query service for price
      this.queriesService.post({
        channel: this.priceCalculationInfo?.channel,
        data: this.formData
      })
        .subscribe(s => {
          console.log("PREVENTIVO", s)
          this.sharedService.loadingMsg$ = ''
          // populate data
          this.confirmActionData.price = 10
          // open popup
          this.sharedService.showActionPopup({
            data: this.confirmActionData
          }).subscribe(() => this.makeRequest())

        })
    } else {
      this.sharedService.showActionPopup({
        data: this.confirmActionData
      }).subscribe(() => this.makeRequest())
    }
  }

}
