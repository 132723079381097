import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldPatterns } from 'src/app/models/service.model';
import { QueriesService } from '../vv/services/queries.service';
import { SubscriptionService } from '../vv/services/subscription.service';
import { combineLatest, delay, map } from 'rxjs';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { SharedService } from 'src/app/shared/shared.service';
import names from './names.json';


function isName(input: string): boolean {
  return !!names.find((n: any) => n == input.toLocaleUpperCase())
}
class SearchResultItem {
  title!: string
  subtitle!: string
  raw?: any
}
@Component({
  selector: 'app-reopera-plus',
  templateUrl: './reopera-plus.component.html',
})
export class ReoperaPlusComponent {
  showSelection: boolean = false
  showSubmitButtons: boolean = false
  isOpen: boolean = false
  selectedItem?: SearchResultItem
  public parsedData: { raw: string, type: string } | null = null
  public extractedMessages: any = {
    PERSON: "codice fiscale persona",
    COMPANY: "codice fiscale azienda"
  }
  public actions: SubscriptionModel[] = []
  public foundData?: SearchResultItem[]
  constructor(
    public route: ActivatedRoute,
    private queryService: QueriesService,
    private subscriptionService: SubscriptionService,
    public sharedService: SharedService,
  ) { }

  precog($event: any) {
    let data: string = $event.target.value

    if (data.match(FieldPatterns.CF)) {
      console.log("CF", data)
      this.parsedData = { raw: data, type: "PERSON" }
      this.sharedService.loadingMsg$ = `Complimenti, hai inserito un
      ${this.extractedMessages[this.parsedData.type]}, procediamo con la
      ricerca...`
      // this.router.navigate(['user', 'consolle-immobiliare', 'ricerche'], { queryParams: { codice_fiscale: data, action: 'go' } })
      this.lookup(data, 'people', { isCf: true })
    }
    else if (data.match(FieldPatterns.COMPANY_CF)) {
      console.log("COMPANY_CF", data)
      this.parsedData = { raw: data, type: "COMPANY" }
      this.sharedService.loadingMsg$ = `Complimenti, hai inserito un
      ${this.extractedMessages[this.parsedData.type]}, procediamo con la
      ricerca...`
      this.lookup(data, 'company', { isCf: true })
    }
    else {
      this.showSubmitButtons = data.trim().includes(' ')
      this.parsedData = null
      this.foundData = undefined
    }
  }

  selectServices(item: SearchResultItem) {
    this.showSelection = true
    this.selectedItem = item
  }

  clear(search: any) {
    search.value = ''
    this.foundData = []
    this.showSubmitButtons = false
  }
  advancedLookup(input: string, type: 'company' | 'people') {
    this.sharedService.loadingMsg$ = `Stiamo cercando...`
    if (type == 'people') {
      let tokens = input.split(' ')
      for (let length = tokens.length - 1; length >= 1; length--) {
        let firstPart = tokens.slice(0, length).join(' ')
        let secondPart = tokens.slice(- length).join(' ')
        if (isName(firstPart)) {
          this.lookup(input, 'people', { isCf: false, extraFields: { nome: firstPart, cognome: input.split(firstPart)[1] } })
          return
        }
        else if (isName(secondPart)) {
          this.lookup(input, 'people', { isCf: false, extraFields: { nome: secondPart, cognome: input.split(secondPart)[0] } })
          return
        }
      }
    } else {
      this.lookup(input, 'company', { isCf: false, extraFields: { cognome: input } })
    }
  }
  lookup(input: string, type: 'company' | 'people', options: { isCf: boolean, extraFields?: any } = { isCf: false }) {
    combineLatest({
      items: this.queryService.post({
        channel: type == 'company' ? "ricerca:aziende" : "ricerca:persone",
        data: {
          "ricercaLibera": options.isCf ? undefined : input,
          "codice_fiscale": options.isCf ? input : undefined,
          ...(options.extraFields || {})
        }
      }).pipe(
        delay(2000),
        map(a => a.items || []),
      ),
      s: this.subscriptionService.subscriptions$
    }).subscribe(({ items, s }) => {
      this.sharedService.loadingMsg$ = ''
      let q = this.route.parent?.routeConfig?.children?.filter(c => c.data?.['serviceSlug']).map(c => c.data?.['serviceSlug']) || []
      this.foundData = items.map((item: any) => ({
        title: item.text,
        subtitle: type == 'company' ?
          `${item.raw.indirizzo.denominazione} - ${item.raw.indirizzo.cap} - ${item.raw.indirizzo.comune} (${item.raw.indirizzo.provincia})` :
          `${item.raw?.luogo_nascita} ${item.raw.data_nascita}`,
        raw: { ...item.raw, tipopersona: type == 'company' ? 'GIURIDICA' : 'FISICA' }
      }))
      this.isOpen = !!this.foundData?.length
      this.actions = s.subscription.filter(sub =>
        q.includes(sub.service.slug)) as unknown as any
    })
  }
}
