import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { catchError, delay, map, of, tap } from 'rxjs';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { QueriesService } from 'src/app/pages/vv/services/queries.service';
import { RequestService } from 'src/app/pages/vv/services/request.service';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { MySubscriptionsQuery } from 'src/graphql/generated';
import { SharedService } from '../../shared.service';
const { v4: uuidv4 } = require('uuid');
@Component({
  selector: 'app-navigazione-container',
  templateUrl: './navigazione-container.component.html',
  styles: [
  ]
})
export class NavigazioneContainerComponent implements OnInit {

  constructor(
    private requestService: RequestService,
    private subscriptionService: SubscriptionService,
    private queriesService: QueriesService,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {
  }
  formData: any = {};
  @Input() entrypoints!: EntryPoint[]
  @Output() onFormRequestSubmit = new EventEmitter()

  currentNavigation = -1
  selectedNavigationData: { description: string, fieldName: string, value: string, raw: any }[][] = [[]]
  navigationItems: { text: string, value: string, raw: any }[][] = []
  availableServices: any[] = []
  ready: boolean = false
  currentEntrypoint?: EntryPoint
  listLayout = true;
  trackingId: string = ''
  confirmActionData: ActionComponentData = {}

  ngOnInit(): void {
    // this.entrypointSelect(this.entrypoints[0])
    this.route.queryParamMap.pipe(
      tap(async (params: ParamMap) => {
        await this.entrypointSelect(this.entrypoints[+(params.get('tab') || 0)])
        // populate formData
        let fields = this.getFields(this.entrypoints[+(params.get('tab') || 0)])
        this.formData = fields.reduce((res, f) => ({ ...res, [f.name]: params.get(f.name) || f.defaultValue }), {})
        console.log(params.keys, params.get('action'))
        if (params.get('action') == 'go') {
          this.onFormSubmit(this.formData)
        }
      })).subscribe()
    this.subscriptionService.subscriptions$.subscribe((s: MySubscriptionsQuery) => {
      this.availableServices = s.subscription
      this.ready = true
    })
  }

  entrypointSelect(item: EntryPoint) {
    this.currentNavigation = -1
    this.currentEntrypoint = undefined
    this.formData = {}

    this.trackingId = uuidv4()
    console.log("OPERATION TRACKING IS NOW", this.trackingId)

    this.navigationItems = new Array(item.navigations.length)
    return new Promise((resolve) => {
      setTimeout(() => {
        this.currentEntrypoint = item
        resolve('')
      }
        , 500)
    })
  }

  goBackTo(step: number) {
    this.currentNavigation = step
  }
  storeCurrentNavigationData(fromStep: number, data: any = undefined) {
    if (data) {
      let dataField = this.currentEntrypoint?.navigations[fromStep].outputParamName || ''
      let currentSelectedNavigationData = {
        description: data.text,
        fieldName: dataField,
        value: data.value,
        raw: { [dataField]: data.value }
      }
      this.selectedNavigationData[fromStep] = [currentSelectedNavigationData]
      this.currentEntrypoint?.navigations[fromStep].extraParamNames?.forEach(epn => {
        this.selectedNavigationData[fromStep].push({
          description: epn.from,
          fieldName: epn.to,
          value: data.raw[epn.from],
          raw: { [epn.to]: data.raw[epn.from] }
        })
      })
    }
    return this.selectedNavigationData[fromStep][0]?.raw
  }

  getQueryData(step: number) {
    let allData = this.selectedNavigationData.map(snd => snd[0]?.raw).reduce((acc, current) => ({ ...acc, ...current }), {})
    allData = { ...allData, ...this.formData }
    let data: any = {}
    this.currentEntrypoint?.navigations[step].inputParams?.forEach((paramName: string) => data[paramName] = allData[paramName])
    console.log("getQueryData", allData, this.currentEntrypoint?.navigations[step].inputParams, data)
    return data
  }

  loadNavigation(fromStep: number, data: any = undefined) {
    if (this.currentEntrypoint?.navigations[fromStep + 1]) {
      let queryData = {}
      if (fromStep >= 0) {

        if (data) {
          this.storeCurrentNavigationData(fromStep, data)
          queryData = this.getQueryData(fromStep + 1)
        } else {
          // go back to some navigation item
          this.currentNavigation = fromStep + 1
          return
        }
      } else {
        queryData = this.formData
      }
      this.sharedService.loadingMsg$ = 'Caricamento... ' + (this.currentEntrypoint.navigations[fromStep + 1]?.name || '')
      this.queriesService.post({
        channel: this.currentEntrypoint.navigations[fromStep + 1]?.channel,
        data: queryData,
        trackingId: this.trackingId
      }).subscribe((d) => {
        if (d.items) {
          this.sharedService.loadingMsg$ = ''
          this.currentNavigation = fromStep + 1
          this.navigationItems[this.currentNavigation] = d.items
          // auto load next data if there's only one option available
          if (d.items.length == 1 && !this.currentEntrypoint?.navigations[this.currentNavigation].actions?.length) {
            this.loadNavigation(this.currentNavigation, d.items[0])
          }
        } else {
          // ERROR!
          this.sharedService.loadingMsg$ = "Qualcosa non va... se il problema persiste, contatta l'assistenza"
          of(true).pipe(
            delay(3000)
          ).subscribe(() => { this.sharedService.loadingMsg$ = "" })

        }

      })
    }
  }

  getCompactViewData(field: ServiceParamFieldModel) {
    switch (field.type) {
      case 'switch':
        return this.formData[field.name] ? 'Sì' : 'No'
    }
    return this.formData[field.name]
  }


  collectParamsAndNotes() {
    // collect params 
    let params: any = { trackId: this.trackingId }
    let notes: any = { trackId: this.trackingId }
    this.selectedNavigationData.forEach(snds => {
      snds.forEach(snd => {
        params[snd.fieldName] = snd.value
        notes[snd.fieldName] = snd.description
      })
    })
    Object.keys(this.formData).forEach((k: string) => {
      notes[k] = this.formData[k]
    })
    return { params, notes }
  }

  makeRequest(data: ActionComponentData) {
    // make request to proper service and await response
    this.sharedService.submitRequestWithParamsAndNotes({
      params: this.formData,
      notes: {},
      confirmActionData: data,
      msg: 'Caricamento...',
      trackingId: this.trackingId
    }).pipe(
      map((res: any) => {
        this.sharedService.loadingMsg$ = ""
        if (res?.status == 'queued' || res?.status == 'completed') {
          this.onFormRequestSubmit.emit(res)
        }
      })).subscribe()
  }

  confirmSubmission() {
    this.sharedService.hideActionPopup()
    this.makeRequest(this.confirmActionData)
  }

  onFormSubmit(data: any) {
    this.formData = data
    if (this.currentEntrypoint?.serviceSlug) {
      let subscription = this.availableServices.find(s => s.service?.slug == this.currentEntrypoint?.serviceSlug)
      if (subscription?.id) {
        if (subscription.price && (this.currentEntrypoint?.confirmPayment == true)) {
          this.confirmActionData = {
            price: subscription.price,
            description: subscription.service?.description,
            title: subscription.service?.name,
            subscriptionId: subscription.id,
            channel: subscription.service?.dataChannel
          }
          this.sharedService.showActionPopup({
            data: this.confirmActionData
          }).subscribe(() => this.confirmSubmission())
        } else {
          this.makeRequest({ subscriptionId: subscription.id, channel: subscription.service?.dataChannel })
        }
      }
    } else {
      this.loadNavigation(-1)
    }
  }

  getPaymentText(currentEntrypoint: EntryPoint) {
    if (currentEntrypoint?.serviceSlug) {
      let subscription = this.availableServices.find(s => s.service?.slug == this.currentEntrypoint?.serviceSlug)
      if (subscription?.price) {
        return subscription.price + '€' + (currentEntrypoint.paymentExtraText || '')
      }
    }
    if (currentEntrypoint.paymentRequired) {
      return '€'
    }
    return ''
  }

  getFields(ep: EntryPoint): ServiceParamFieldModel[] {
    if (ep.fieldsFromService) {
      let subscription = this.availableServices.find(s => s.service?.slug == ep?.serviceSlug)
      return subscription?.service?.fields || []
    }
    else {
      return ep.fields
    }
  }
}

