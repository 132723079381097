import { Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  env = environment
  constructor(private title: Title, private renderer: Renderer2) {
    this.title.setTitle(this.env.title)
    const url = environment.theme ? 'assets/themes/' + environment.theme + '.css' : null;
    if (url) {
      const link = this.renderer.createElement("link");
      this.renderer.setAttribute(link, "rel", "stylesheet")
      this.renderer.setAttribute(link, "type", "text/css")
      this.renderer.setAttribute(link, "href", url)
      this.renderer.appendChild(document.head, link)
    }
    let favIconElm: HTMLLinkElement | null = document.querySelector('#appIcon');
    const favicon = environment.favicon || null
    if (favicon && favIconElm) {
      favIconElm.href = '/assets/' + favicon
    }
  }
}
