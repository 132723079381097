import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, delay, map, of, tap } from 'rxjs';
import { NavigationDetailsConfig, NavigationActionsConfig, NavigationConfig } from 'src/app/models/navigation.shared.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { QueriesService } from 'src/app/pages/vv/services/queries.service';
import { RequestService } from 'src/app/pages/vv/services/request.service';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';
import { MySubscriptionsQuery } from 'src/graphql/generated';
import { ActionComponentData } from '../actionpopup/actionpopup.component';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-navigazione-results',
  templateUrl: './navigazione-results.component.html',
  styleUrls: []
})
export class NavigazioneResultsComponent implements OnInit {

  constructor(
    private requestService: RequestService,
    private subscriptionService: SubscriptionService,
    private queriesService: QueriesService,
    private sharedService: SharedService
  ) { }

  @Input() listLayout: boolean = true;
  @Input() compact: boolean = false;
  @Input() nextButtonTitle: string = '';
  @Input() items: { text: string, value: string, raw: any }[] = []
  @Input() navigation!: NavigationConfig
  @Input() navigationIdx = 0
  @Input() trackingId?: string
  @Input() currentNavigation = 0
  @Input() paramsAndNotes: { params: any, notes: any } = { params: {}, notes: {} }
  @Output() onSelectedItem = new EventEmitter()
  @Output() onLaunchAction = new EventEmitter()

  p: number = 1;
  availableServices: any[] = []
  confirmActionData: ActionComponentData = {}
  showSubmissionConfirm: boolean = false
  submissionConfirmButtons: any = null

  ngOnInit(): void {
    this.subscriptionService.subscriptions$.subscribe((s: MySubscriptionsQuery) => {
      this.availableServices = s.subscription
    })
  }

  populateDetails(details: NavigationDetailsConfig[], item: any) {
    let populated = details.map(d => ({ ...d, value: "" }))
    populated.forEach(p => p.value = Array.isArray(p.field) ? p.field.map(f => item[f] || '').join(p.separator || " ") : item[p.field])
    return populated
  }
  populateActions(actions: NavigationActionsConfig[]): NavigationActionsConfig[] {
    return actions.map(
      a => ({ ...a, subscription: this.availableServices.find(s => s.service?.slug == a.slug) })
    ).filter(a => a.subscription)
      .map(a => ({ ...a, isFree: !a.subscription || !a.subscription.price }))
  }
  launchAction(data: NavigationActionsConfig, item: any, i: number, paramsAndNotes: any) {
    // inform parent component about selected item
    this.onLaunchAction.emit(item)

    // collect params 
    let { params, notes } = paramsAndNotes

    // enrich params with current item data
    params[this.navigation.outputParamName || ''] = item.value
    notes[this.navigation.outputParamName || ''] = item.text

    // collect required action params
    data.params?.forEach(p => {
      if (p.userParam) {
        params[p.requestParam] = item?.raw[p.userParam]
      } else if (p.value) {
        params[p.requestParam] = p.value
      }
    })

    this.confirmActionData = {
      title: data.title,
      description: data.subscription?.service?.description || '',
      subscriptionId: data.subscription?.id || 0,
      price: data.subscription?.price || 0,
      type: data.type,
      extra: { params, notes },
      submissionButtons: data.subscription?.service?.postConfirmButtons
    }

    if (data.subscription?.service?.priceCalculation?.channel) {
      let pcParams: any = {}
      Object.keys(params).filter(
        k => (data.subscription?.service?.priceCalculation?.params || []).includes(k)
      ).forEach(
        k => pcParams[k] = params[k]
      )
      console.log("PARAMS", {
        priceCalculation: data.subscription?.service?.priceCalculation,
        data: params,
        filteredData: pcParams
      })
      this.sharedService.loadingMsg$ = 'Caricamento costi...'
      // query service for price
      this.queriesService.post<{ raw: any }>({
        channel: data.subscription?.service?.priceCalculation?.channel,
        data: pcParams
      })
        .subscribe(s => {
          console.log("PREVENTIVO", s)
          this.sharedService.loadingMsg$ = ''
          // populate data
          this.confirmActionData.price = s.raw._costo
          // open popup
          this.sharedService.showActionPopup({
            data: this.confirmActionData
          }).subscribe(() => this.submitRequest())

        })
    } else {
      if (data.subscription?.price) {
        this.sharedService.showActionPopup({
          data: this.confirmActionData
        }).subscribe(() => this.submitRequest())
      } else {
        this.submitRequest()
      }

    }

  }

  submitRequest() {
    let { params, notes } = this.confirmActionData.extra || this.paramsAndNotes
    this.sharedService.hideActionPopup()
    this.sharedService.submitRequestWithParamsAndNotes({
      params,
      notes,
      confirmActionData: this.confirmActionData,
      trackingId: this.trackingId
    }).pipe(
      map((res: any) => {
        this.sharedService.loadingMsg$ = ""
        if (res?.status == 'queued') {
          this.showSubmissionConfirm = true
          this.submissionConfirmButtons = this.confirmActionData.submissionButtons
        }
      })
    ).subscribe()
  }

}
