import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Route } from '@angular/router';
import { Observable, Subject, combineLatest, delay, filter, map, of, pluck, tap } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SubscriptionService } from '../../../pages/vv/services/subscription.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-dynamic-workarea',
  templateUrl: './workarea.component.html',
  //   styles: [`
  //  :host{
  //      height: 100%;
  //      position: relative;
  //  }`]
})
export class DynamicWorkareaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService
  ) { }
  menuitem!: Route | null | undefined
  currentWorkarea?: any = null
  showIntroText$!: Observable<string>
  ready: boolean = false
  introTextSeen: boolean = false
  ngOnInit(): void {
    this.menuitem = this.route.routeConfig?.children ? this.route.routeConfig : this.route.parent?.routeConfig
    this.showIntroText$ = of(this.storageService.getStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      storageArea: 'sessionStorage'
    }) || 'true'
    );
    combineLatest({
      params: this.route.paramMap,
      activations: this.subscriptionService.activations$
    })
      .pipe(
        tap(() => {
          this.ready = false
        }),
        delay(10),
        tap(({activations, params}) => {
        if ( params.get('workarea_slug')) {
          this.currentWorkarea = activations.workarea_activation.find(waa => waa.workarea.slug == params.get('workarea_slug'))?.workarea
          if (params.get('item_slug')) {
            this.menuitem = {
              children: this.currentWorkarea.config?.menu?.children?.
              find((m: any) => m.path == params.get('item_slug')).
              children?.map((item: any) => ({
                path: item.path || item.data.serviceSlug,
                data: {
                  ...(item.data || {}),
                  id: item.data.serviceSlug,
                  // workareaSlug: item.slug,
                  children: undefined
                }
              }))
            }
          } else {
            this.menuitem = {
              children: this.currentWorkarea.config?.menu?.children?.map((item: any) => ({
                path: `${item.path}`,
                children: item.children || [],
                data: {
                  ...(item.data || {}),
                  id: item.path,
                  // workareaSlug: item.slug,
                  children: undefined
                }
              }))
            }
          }
        }
        this.ready = true
      })
    )
.subscribe()
  }
  goOn() {
    this.storageService.setStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      value: "false",
      storageArea: 'sessionStorage'
    })
    this.showIntroText$ = of(this.storageService.getStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      storageArea: 'sessionStorage'
    }) || 'true')
  }

}
