import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { QueryItem } from 'src/app/models/query.model';
import { QueriesService } from '../services/queries.service';

@Component({
  selector: 'app-monitoraggio',
  templateUrl: './monitoraggio.component.html',
  styles: [
  ]
})
export class MonitoraggioComponent implements OnInit {

  constructor(private queryService: QueriesService) { }
  monitoraggi$!: Observable<QueryItem[]>;
  currentEventList = -1
  columns = [
    {
      value: 'tipo', text: "Tipo", iconMap: {
        'CATASTO_SOGGETTO': ['fa-person'],
        'CATASTO_IMMOBILE': ['fa-building']
      }
    },
    { value: 'data_inizio', text: "Data inizio" },
    { value: 'data_fine', text: "Data fine" },
    { value: 'data_ultimo_controllo', text: 'Data ultimo controllo' },
  ]

  getMonitoraggi() {
    this.monitoraggi$ = this.queryService.post({
      channel: 'monitoraggio:lista',
      data: {}
    }).pipe(map(x => x.items || []))

  }

  countEventiNonLetti(item: any) {
    return item.raw.eventi_rilevati.filter((a: any) => !a.letto).length
  }
  ngOnInit(): void {
    this.getMonitoraggi()
  }

  switchRinnovoAutomatico(identificativo_monitoraggio: string, stato_rinnovo_automatico: boolean) {
    this.queryService.post({
      channel: 'monitoraggio:rinnovo:switch',
      data: {
        identificativo_monitoraggio,
        stato_rinnovo_automatico: !stato_rinnovo_automatico
      }
    }).subscribe(() => this.getMonitoraggi())
  }
  setStatoLettura(identificativo_evento: string, stato_lettura: boolean) {
    this.queryService.post({
      channel: 'monitoraggio:eventi:status',
      data: {
        identificativo_evento, stato_lettura
      }
    }).subscribe()
  }
  rimuovi(identificativo_monitoraggio: string) {
    this.queryService.post({
      channel: 'monitoraggio:rimuovi',
      data: identificativo_monitoraggio
    }).subscribe(() => this.getMonitoraggi())

  }

  getIcon(detail: any, item: any) {
    return detail.iconMap?.[item.raw[detail.value]]
  }
}
