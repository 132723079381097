import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from 'apollo-angular/http';
import { WebSocketLink } from "@apollo/client/link/ws";
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { getMainDefinition } from "@apollo/client/utilities";
import { firstValueFrom, map } from 'rxjs';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PagesModule } from './pages/pages.module';


export function createApollo(httpLink: HttpLink, authService: AuthService): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: env.graphql_endpoint,
    headers: new HttpHeaders().set(
      "X-Hasura-Role", "user"
    )
  })
  const ws: WebSocketLink = new WebSocketLink({
    uri: env.ws_graphql,
    options: {
      reconnect: true,
      connectionParams: firstValueFrom(
        authService.getAccessTokenSilently().pipe(
          map(x => {
            return {
              authToken: 'Bearer ' + x,
              headers: {
                'Authorization': `Bearer ${x}`,
                "X-Hasura-Role": "user"
              }
            }
          }),
        ))
    }
  });

  const link = split(
    ({ query }) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http
  )
  return {
    cache: new InMemoryCache(),
    link: link
  }
}
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ApolloModule,
    HomeModule,
    HttpClientModule,
    LeafletModule,
    AuthModule.forRoot({
      ...env.auth
    }),
    PagesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: (httpLink: HttpLink) => {
    //     return {
    //       cache: new InMemoryCache(),
    //       link: httpLink.create({
    //         uri: env.graphql_endpoint,
    //       })
    //     };
    //   },
    //   deps: [HttpLink],
    // }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
