import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable, tap } from 'rxjs';
import { RequestModel } from 'src/app/models/request.model';
import { QueriesService } from '../../services/queries.service';

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styles: [
  ]
})
export class ReportPreviewComponent implements OnInit {
  @Input() request!: RequestModel
  @Output() onClose = new EventEmitter()
  report?: SafeHtml
  constructor(
    private queriesService: QueriesService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getReportPreview()
  }

  getReportPreview() {
    this.queriesService.postTextResponse({
      channel: 'report:preview',
      data: this.request
    }).subscribe(res => {
      this.report = this.sanitizer.bypassSecurityTrustHtml(res)
    })
  }

}
