import { Component, OnInit } from '@angular/core';
import 'proj4leaflet'
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { QueriesService } from '../services/queries.service';
import { filter, tap } from 'rxjs';
import { DatiCatastali } from 'src/app/models/catasto.model';
@Component({
    selector: 'app-geocatasto',
    templateUrl: './geocatasto.component.html',
    styles: [`
    :host{
        height: 100%;
        position: relative;
    }`]
})
export class GeoCatastoComponent implements OnInit {

    constructor(
        private queriesService: QueriesService,
    ) { }

    marker?: any
    selectedPos?: LatLng
    selectedCatastoInfo?: DatiCatastali
    showLowerPanel: boolean = false

    ngOnInit(): void { }
    onClick($event: LeafletMouseEvent) {
        this.showLowerPanel = false
        console.log("GEOCATASTO::ONCLICK", $event.latlng)
        this.selectedPos = $event.latlng
        this.queriesService.post<{ TIPOLOGIA: string, FOGLIO: number, NUM_PART: number, COD_COMUNE: string, SEZIONE: string, DENOM: string }>({
            channel: 'mappe:geocode:reverse',
            data: { lat: $event.latlng.lat, lng: $event.latlng.lng }
        }).pipe(
            filter(x => x.TIPOLOGIA == 'PARTICELLA'),
            // tap(x => window.alert(`${x.DENOM}\n ${x.TIPOLOGIA}:  ${[x.FOGLIO, x.NUM_PART].join('/')}`)),
            tap((x) => {
                this.selectedCatastoInfo = {
                    foglio: x.FOGLIO,
                    particella: x.NUM_PART,
                    codice_comune_catastale: x.COD_COMUNE,
                    codice_sezione_catastale: x.SEZIONE,
                    tipo_catasto: 'TERRENI'
                }
                this.marker = {
                    selectedPos: this.selectedPos,
                    selectedCatastoInfo: this.selectedCatastoInfo,
                    latlng: { lat: $event.latlng.lat, lng: $event.latlng.lng },
                    popupText: `${x.DENOM}\n ${x.TIPOLOGIA}:  ${[x.FOGLIO, x.NUM_PART].join('/')}`,
                    isPopupOpen: true
                }
            })
        ).subscribe()
    }


    clearMarkerData() {
        this.marker = undefined
        this.selectedPos = undefined
        this.selectedCatastoInfo = undefined
    }
}
