import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable, tap, switchMap } from 'rxjs';
import { GetVisuraIpotecariaGQL, GetVisuraIpotecariaQuery, Request } from 'src/graphql/generated';
import { formalita_navigation } from '../conservatoria.ui.config';
import { RequestModel } from 'src/app/models/request.model';

@Component({
  selector: 'app-lista-formalita',
  templateUrl: './lista-formalita.component.html',
  styleUrls: []
})
export class ListaFormalitaComponent implements OnInit {
  @Input() listLayout: boolean = true
  @Input() uuid!: string
  navigation = formalita_navigation
  request$?: Observable<any | undefined>

  constructor(private route: ActivatedRoute,
    private getVisuraIpotecariaService: GetVisuraIpotecariaGQL
  ) { }

  ngOnInit(): void {
    this.request$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.getVisuraIpotecariaService.watch({
          _eq: 'visura-ipotecaria',
          _contains: `%${params.get('uuid')}%`
        }).valueChanges.pipe(
          map(s => {
            let timeLimit = new Date()
            timeLimit.setDate(timeLimit.getDate() - 5)
            if (new Date(s.data.request?.[0]?.createdAt) < timeLimit) {
              this.navigation = { ...this.navigation, actions: [] }
            }
            return s.data.request?.[0]
          }),
          map(r => ({
            ...r,
            _description: r.params._description || (r.params.id_bene && (`${r.params.tipo_catasto || ''} ` + [r.params.foglio, r.params.particella, r.params.subalterno].filter(a => a).join('/'))) || 'Richiesta generica'
          })),
        )
      )
    )
  }

}
