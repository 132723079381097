import { EntryPoint, NavigationConfig } from "src/app/models/navigation.shared.model"
import { FieldPatterns } from "src/app/models/service.model"


export const pregiudizievoliConfig: EntryPoint[] = [
    {
        name: "Ricerca persone",
        isActive: true,
        submitText: 'Ricerca',
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "CF"
        }, {
            name: "cognome",
            description: "Cognome"
        }, {
            name: "nome",
            description: "Nome"
        }
        ],
        navigations: [
            {
                name: "Soggetti",
                nameAfterSelect: "",
                channel: "ricerca:persone",
                outputParamName: "codice_fiscale",
                isSelectable: false,
                details: [
                    { title: "Codice Fiscale", description: "", icon: "fa-regular fa-user", field: 'codice_fiscale', copiable: true },
                    { title: "Cognome", description: "", icon: "fa-regular fa-user", field: 'cognome' },
                    { title: "Nome", description: "", icon: "fa-regular fa-user", field: 'nome' },
                    { title: "Sesso", description: "", icon: "fa-regular fa-user", field: 'sesso' },
                    { title: "Data Nascita", description: "", icon: "fa-regular fa-user", field: 'data_nascita' },
                    { title: "Luogo Nascita", description: "", icon: "fa-regular fa-user", field: 'luogo_nascita' },

                    { title: "Catasto", description: "", icon: "fa-regular fa-user", field: 'catasto_light', type: 'light' },
                    { title: "CCIAA", description: "", icon: "fa-regular fa-user", field: 'cciaa_light', type: 'light' },
                ],
                actions: [
                    // {
                    //     title: "Valuta cliente", slug: "report-persone", type: "request",
                    //     params: [
                    //         { userParam: 'codice_fiscale', requestParam: 'codiceFiscale' },
                    //         { requestParam: 'tipopersona', value: 'FISICA' }]
                    // },
                    // {
                    //     title: "Stima Immobiliare", slug: "stima-immobiliare", type: "request",
                    //     params: [
                    //         { userParam: 'codice_fiscale', requestParam: 'codiceFiscale' },
                    //         { value: 'FISICA', requestParam: 'tipopersona' }]
                    // },
                    // {
                    //     title: "Cariche / Partecipazioni", slug: "cariche-partecipazioni", type: "request",
                    //     params: [
                    //         { userParam: 'codice_fiscale', requestParam: 'codiceFiscale' },
                    //         { value: 'FISICA', requestParam: 'tipopersona' }]
                    // }
                ]
            }
        ]
    },
    {
        name: "Ricerca aziende",
        isActive: true,
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "COMPANY_CF",
        }, {
            name: "ricercaLibera",
            description: "Denominazione"

        }],
        navigations: [
            {
                name: "Info",
                nameAfterSelect: "Azienda selezionata",
                channel: "ricerca:aziende",
                outputParamName: "codice_fiscale",
                isSelectable: false,
                details: [
                    { title: "Codice Fiscale", description: "", icon: "fa-regular fa-id-card", field: 'codice_fiscale' },
                    { title: "Denominazione", description: "", icon: "fa-solid fa-signature", field: "denominazione" },
                    { title: "Natura Giuridica", description: "", icon: "fa-solid fa-signature", field: "natura_giuridica" },
                    { title: "Capitale", description: "", icon: "fa-solid fa-signature", field: "capitale_sociale_deliberato", suffix: '€' },
                    { title: "REA", description: "", icon: "fa-regular fa-id-card", field: ['cciaa', 'numero_rea'], separator: '-' },
                    { title: "Stato", description: "", icon: "fa-solid fa-medal", field: "stato_attivita" },
                ],
                actions: [
                    // {
                    //     title: "Bilancio ottico", slug: "cciaa-bilancio-ottico", type: 'request',
                    //     params: [
                    //         { requestParam: 'id', userParam: 'id' },
                    //         { requestParam: 'data_chiusura', userParam: 'data_chiusura' },
                    //         { requestParam: 'codice_tipo_bilancio', userParam: 'codice_tipo_bilancio' },
                    //         { requestParam: 'denominazione', userParam: 'denominazione' },
                    //         { requestParam: 'codiceFiscale', userParam: 'codice_fiscale' }]
                    // },
                    // {
                    //     title: "Bilancio riclassificato", slug: "cciaa-bilancio-riclassificato", type: 'request',
                    //     params: [
                    //         { requestParam: 'id', userParam: 'id' },
                    //         { requestParam: 'data_chiusura', userParam: 'data_chiusura' },
                    //         { requestParam: 'codice_tipo_bilancio', userParam: 'codice_tipo_bilancio' },
                    //         { requestParam: 'denominazione', userParam: 'denominazione' },
                    //         { requestParam: 'codiceFiscale', userParam: 'codice_fiscale' }]
                    // }
                ]
            }
        ]
    }
]
