import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Route } from '@angular/router';
import { Observable, Subject, combineLatest, filter, map, of, pluck, tap } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-workarea',
  templateUrl: './workarea.component.html',
  //   styles: [`
  //  :host{
  //      height: 100%;
  //      position: relative;
  //  }`]
})
export class WorkareaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService
  ) { }
  menuitem!: Route | null | undefined
  currentWorkarea?: any = null
  showIntroText$!: Observable<string>
  ready: boolean = false
  introTextSeen: boolean = false
  ngOnInit(): void {
    this.menuitem = this.route.routeConfig?.children ? this.route.routeConfig : this.route.parent?.routeConfig
    this.showIntroText$ = of(this.storageService.getStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      storageArea: 'sessionStorage'
    }) || 'true'
    );

    this.subscriptionService.activations$.pipe(
      tap((activations) => {
        if (this.route.parent?.routeConfig?.data?.['workareaSlug']) {
          this.currentWorkarea = activations.workarea_activation.find(waa => waa.workarea.slug == this.route.parent?.routeConfig?.data?.['workareaSlug'])?.workarea
        }
        this.ready = true
      })
    ).subscribe()
  }
  goOn() {
    this.storageService.setStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      value: "false",
      storageArea: 'sessionStorage'
    })
    this.showIntroText$ = of(this.storageService.getStorageItem({
      key: "workareaIntro-" + this.route.parent?.routeConfig?.data?.['workareaSlug'],
      storageArea: 'sessionStorage'
    }) || 'true')
  }

}
