import { Component, Input, OnInit } from '@angular/core';
import { PushNotificationsService } from 'src/app/shared/pushnotification.service';
import v from '../../../../../package.json'
import { routes } from '../../../app-routing.module'
import { Route } from '@angular/router';
import { MyActivationsGQL } from 'src/graphql/generated';
import { SharedService } from 'src/app/shared/shared.service';
import { environment as env } from '../../../../environments/environment';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: [
  ]
})
export class NavBarComponent implements OnInit {

  constructor(
    private _notification: PushNotificationsService,
    private activationService: MyActivationsGQL,
    public sharedService: SharedService) { }

  @Input() isNavBarOpen!: boolean

  version = `v${v.version}`
  items: Route[] = []
  // routes[0].children?.[1]?.children?.filter(c => c.data?.['name']) || []
  admin_url = env.admin_endpoint
  logo = env.logo
  ngOnInit(): void {

    this.activationService.watch().valueChanges.subscribe(waa => {
      this.items = [
        ...waa.data.workarea_activation.map(item => ({
          path: item.workarea.slug,
          children: item.workarea.config?.menu?.children || [],
          data: {
            ...(item.workarea.config?.menu || {}),
            id: item.workarea.slug,
            workareaSlug: item.workarea.slug,
            name: item.workarea.name,
            children: undefined
          }
        }))
      ]
    })
  }
  checkNotification() {
    this._notification.requestPermission()
  }
}
