import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, iif, Observable, of, switchMap } from 'rxjs';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';

@Component({
  selector: 'app-service-card',
  templateUrl: './service.card.component.html',
  styleUrls: []
})
export class ServiceCardComponent implements OnInit {

  constructor(
    private subscriptionService: SubscriptionService
  ) {
  }
  targetMap: any = {
    companies: { text: "aziende", icon: "fa-industry" },
    people: { text: "persone", icon: "fa-person" },
    buildings: { text: "immobili", icon: "fa-house" },
    lands: { text: 'terreni', icon: "fa-map-location-dot" }
  }
  executionPolicyMap: any = {
    realtime: { text: "tempo reale", icon: "fa-regular fa-clock" },
    deferred: { text: "24h lavorative", icon: "fa-regular fa-clock" },
    long: { text: "8/10gg lavorativi", icon: "fa-regular fa-clock" }
  }
  @Input() slug!: string
  @Input() serviceIcon?: string
  @Input() serviceImg? : string
  @Input() forceLink?: string
  @Input() buttonText?: string
  @Input() preventRoute: boolean = false
  @Output() onServiceSelected = new EventEmitter()

  open: boolean = false
  service$!: Observable<SubscriptionModel>
  ngOnInit(): void {
    this.service$ = this.subscriptionService.getByServiceSlug(this.slug)

  }

}
